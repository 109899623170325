<script setup lang="ts">
import {PrimeText} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import {
	OFFERWALLS_TITLE,
} from '@/locales/constants';
import {useScreenSize} from "@/utils/composables/useScreenSize";
import OfferwallsDescription from "@/views/offerwalls/views/partners/components/OfferwallsDescription.vue";

const props = defineProps<{
	tabs: string[];
}>();

const currentTab = defineModel<string>();
const { isMobile } = useScreenSize();

function updateTab(tab: string) {
	currentTab.value = tab
}
</script>

<template>
	<div class="offers-header">
		<PrimeText
			tag="h5"
			color="inherit"
			weight="500"
			size="2xl"
		>
			{{ $t(OFFERWALLS_TITLE) }}
		</PrimeText>
		<OfferwallsDescription />
<!--		<div class="offers-tabs">-->
			<!--			<div-->
<!--				v-for="tab in tabs"-->
<!--				:key="tab"-->
<!--				class="tab-item"-->
<!--				:class="{ active: currentTab === tab }"-->
<!--				@click="updateTab(tab)"-->
<!--			>-->
<!--				<PrimeText size="sm" color="inherit">-->
<!--					{{ tab }}-->
<!--				</PrimeText>-->
<!--			</div>-->
<!--		</div>-->
	</div>
</template>

<style scoped lang="scss">
@import '@/styles/mixins.scss';

.offers {
	&-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: calc(100% - 0.5rem);
		margin-bottom: 1rem;
		color: white;

		@include breakpoint(tablet) {
			width: 100%;
			margin-bottom: 1.5rem;
		}

		&-btns {
			height: 34px;
			display: flex;
			align-items: center;
			cursor: pointer;
			gap: 1.5rem;
		}
	}

	&-tabs {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: #151515;
		border-radius: 3.125rem;
		height: 2.5rem;
		width: 100%;
		max-width: 350px;
		margin: 0 auto;

		.tab-item {
			cursor: pointer;
			padding: 10px;
			color: white;
			border-radius: 3.125rem;
			min-width: 10.625rem;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 2.5rem;
		}
		.active {
			background-color: white;
			color: #151515;
		}

		@include breakpoint(mobile) {
			margin: 0;
			width: auto;
		}
	}
}
</style>

<script lang="ts" setup>
import {
	EMAIL_PREFERENCES,
	EMAIL_SUBS_TITLE,
	EMAIL_SUBS_SAVE,
	EMAIL_SUBS_SHOW_ALL,
} from '@/locales/constants';
import LayoutSubpage from '@/components/LayoutSubpage.vue';
import { useEmailSubscriptionsView } from '@/views/email-subscriptions/composables';
import { PrimeText, PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import SubscriptionItem from '@/views/email-subscriptions/components/SubscriptionItem.vue';
import { ref } from 'vue';

const {
	subsToShow,
	isAllSubsShow,
	isAuthorized,
	emailSubsTranslations,
	handleSave,
} = useEmailSubscriptionsView();

const changeApplied = ref<boolean>(false);

const applyChanges = (subscription: SubscriptionItem, event: boolean) => {
	subscription.subscribed = event;
	changeApplied.value = true;
};

const save = () => {
	changeApplied.value = false;
	handleSave();
};
</script>

<template>
	<main class="email-subs">
		<LayoutSubpage
			:title="$t(EMAIL_PREFERENCES)"
			:back-button-visible="isAuthorized"
		>
			<div class="email-subs-container">
				<div>
					<PrimeText tag="h2" weight="500" size="base" color="inherit">
						{{ $t(EMAIL_SUBS_TITLE) }}
					</PrimeText>
					<div v-if="subsToShow" class="email-subs__items">
						<SubscriptionItem
							v-for="(subscription, index) in subsToShow"
							:key="index"
							:type="subscription.type"
							:subscribed="subscription.subscribed"
							:title="$t(emailSubsTranslations[subscription.type]?.title)"
							:description="
								$t(emailSubsTranslations[subscription.type]?.description)
							"
							@change="applyChanges(subscription, $event)"
						/>
					</div>
				</div>
				<template v-if="!isAuthorized">
					<button
						v-if="!isAllSubsShow"
						class="email-subs__show-all-btn"
						@click="isAllSubsShow = true"
					>
						{{ $t(EMAIL_SUBS_SHOW_ALL) }}
					</button>
				</template>
				<div class="email-subs-footer">
					<PrimeButton
						:label="$t(EMAIL_SUBS_SAVE)"
						:disabled="!subsToShow || !changeApplied"
						@click="save"
					/>
				</div>
			</div>
		</LayoutSubpage>
	</main>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.email-subs {
	width: 100%;
	color: white;

	&-container {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: calc(100% - 9rem);
		padding-bottom: env(safe-area-inset-bottom);
	}

	&-footer {
		width: 100%;
		text-align: center;
	}

	&__show-all-btn {
		margin-bottom: 1.5rem;
		cursor: pointer;
		border: 0;
		color: var(--base-primary);
		background: transparent;
		font-size: 0.75rem;
		text-decoration: underline;

		&:hover {
			color: var(--base-primary-hover);
		}
	}
}
</style>

export const AUTH_ROUTE_NAME = {
	HOME: 'home',
	APP_LOGIN: 'app-login',
	SIGN_IN: 'signin',
	SIGN_UP: 'signup',
	SIGN_UP_COMPLETE: 'signup-complete',
	FORGOT_PASSWORD: 'forgot-password',
	SOCIALS: 'socials',
	AUTH_SOCIAL: 'auth-social',
	SIGN_UP_SOCIAL: 'signup-social',
	RESET_PASSWORD: 'reset-password',
	SIGNATURE_LOGIN: 'signature-login',
};

export const USER_ROUTE_NAME = {
	SURVEYS: 'surveys',
	PROFILE: 'profile',
	SETTINGS: 'settings',
	ACCEPT_EMAIL_TRANSFER: 'accept-email-transfer',
	REWARD_HISTORY: 'reward-history',
	TRANSACTIONS_HISTORY: 'transactions-history',
	LEADERBOARD: 'leaderboard',
	CONFIRM_CLAIM: 'confirm-claim',
	PRIZE_DRAW: 'prize-draw',
	REFERRALS: 'referrals',
	RECENT_ACTIVITY: 'recent-activity',
	SUPPORT: 'support',
	EMAIL_SUBSCRIPTIONS: 'email-subscriptions',
	ACHIEVEMENTS: 'achievements',
	BONUS_DAY: 'bonus-day',
	VERIFY_EMAIL: 'verify-email',
	MORE: 'more',
	OFFERWALLS: 'offerwalls',
	OFFERWALLS_PARTNERS: 'offerwalls-partners',
	OFFERWALLS_PARTNER: 'offerwalls-partner',
};

export const UTILITY_ROUTE_NAME = {
	LOGOUT: 'logout',
};
